import {
  FC,
  MutableRefObject,
  useContext,
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
} from 'react'
import dynamic from 'next/dynamic'
import { useTranslation } from 'next-i18next'
import { ROUTES } from '@utils/routes'
import { useUI } from '@contexts/ui.context'
import { useActiveScroll } from '@utils/use-active-scroll'
import Container from '@components/ui/container'
import Logo from '@components/ui/logo'
import UserIcon from '@components/icons/user-icon'
import MenuIcon from '@components/icons/menu-icon'
import LanguageSwitcher from '@components/ui/language-switcher'
import { useModalAction } from '@components/common/modal/modal.context'
import cn from 'classnames'
import Search from '@components/common/search'
import { IUser } from '@contexts/user/user.utils'
import { UserContext } from '@contexts/user/user.context'
import { useSession } from 'next-auth/react'
import Router from 'next/router'
import Link from '@components/ui/link'
import { IoIosArrowDown } from 'react-icons/io'
import Image from 'next/image'
import { siteSettings } from '@settings/site-settings'
import { useRouter } from 'next/router'
import { useCart } from '@contexts/cart/cart.context'
import { FaPhoneAlt } from 'react-icons/fa'
import { FaUser, FaUserLarge } from 'react-icons/fa6'
import { FiEdit } from 'react-icons/fi'
import { AiOutlineUser } from 'react-icons/ai'
import { IoIosArrowForward } from 'react-icons/io'
import BrandsGrid from '@components/home-one/shopByBrandItem'
import { useCategoriesQuery } from '@framework/category/get-all-categories'
import mag_categories from '../../../../public/api/mag_categories.json'
import { CART_SIDEBAR } from '@constants/index'
import { updateSessionStore } from '@utils/helper'
import { useLocalStorage } from '@utils/use-local-storage'

// api/mag_categories.json
const AuthMenu = dynamic(() => import('./auth-menu'), { ssr: false })
const CartButton = dynamic(() => import('@components/cart/cart-button'), {
  ssr: false,
})

type DivElementRef = MutableRefObject<HTMLDivElement>

const HeaderNew: FC = () => {
  const { resetCart, logoutResetCart } = useCart()
  const [localCartError, , removeLocalCartError] = useLocalStorage('cart-error')
  const [localLoggedOut, , removeLocalLoggedOut] = useLocalStorage('logged-out')

  const { data: session } = useSession()

  const { getCart, setCreateCart } = useContext(UserContext) as IUser

  useLayoutEffect(() => {
    if (session === null && localLoggedOut) {
      logoutResetCart()
      setCreateCart(true)
      removeLocalLoggedOut()
    } else {
      updateSessionStore(session)
    }
  }, [])

  useEffect(() => {
    if (localCartError) {
      resetCart()
      removeLocalCartError()
      localStorage.clear()
    }
  }, [localCartError])

  const router = useRouter()
  const { closeModal, openModal } = useModalAction()
  const { openSidebar, isAuthorized, displayMobileSearch } = useUI()
  const { user }: { user: any } = useContext(UserContext) as IUser
  const { t: translate } = useTranslation(['common', 'categories'])
  const isCheckoutPage = router.pathname === '/checkout'
  const { openDrawer, setDrawerView } = useUI()
  const { totalItems } = useCart()
  const siteHeaderRef = useRef() as DivElementRef
  useActiveScroll(siteHeaderRef)
  const [isOpenShopByDepart, setIsOpenShopByDepart] = useState(false)
  const [isOpenShopByBrand, setIsOpenShopByBrand] = useState(false)
  const [cat_l2, setCat_l2] = useState([])
  const [catId, setCatId] = useState('2821')
  const modalRef = useRef(null)
  const shopByDeptRef = useRef(null)
  const shopByBrandRef = useRef(null)
  const [selected, setSelected] = useState('Foods') // Track selected item

  // Toggle the dropdown visibility
  const shopByDepartDropdown = () => {
    setIsOpenShopByDepart(!isOpenShopByDepart)
    setIsOpenShopByBrand(false)
  }
  // Toggle selected item on click
  const handleClick = (item) => {
    setSelected(item)
  }

  const shopByBrandDropdown = () => {
    setIsOpenShopByBrand(!isOpenShopByBrand)
    setIsOpenShopByDepart(false)
  }
  function handleLogin() {
    openModal('LOGIN_VIEW')
  }
  function handleSignUp() {
    Router?.push(ROUTES?.SIGN_UP)
  }
  function handleMobileMenu() {
    return openSidebar()
  }

  function handleCartOpen() {
    if (totalItems > 0) getCart()
    setDrawerView(CART_SIDEBAR)
    // isShowing;
    return openDrawer()
  }

  function handleCartPage() {
    router.push(ROUTES.CART)
  }

  function getAllChildren(parentId, categories) {
    let result = []

    categories.forEach((category) => {
      if (category.parent_id === parentId && category.level === '3') {
        result.push(category)
      }
    })

    return result
  }

  useEffect(() => {
    const children = getAllChildren(catId, mag_categories?.items)
    setCat_l2(children)
  }, [catId])

  useEffect(() => {
    function handleClickOutside(event) {
      // Check if the click is outside the modal or if "Shop by Department" is not clicked
      if (
        isOpenShopByDepart &&
        modalRef.current &&
        !modalRef.current?.contains(event.target) &&
        !shopByDeptRef.current?.contains(event.target)
      ) {
        setIsOpenShopByDepart(false) // Close the modal
      }

      // Check if the click is outside the modal or if "Shop by Brand" is not clicked
      if (
        isOpenShopByBrand &&
        modalRef.current &&
        !modalRef.current?.contains(event.target) &&
        !shopByBrandRef.current?.contains(event.target)
      ) {
        setIsOpenShopByBrand(false) // Close the modal
      }
    }

    // Add event listener to detect clicks
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      // Cleanup the event listener when component unmounts
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpenShopByDepart, isOpenShopByBrand])

  const columnClass =
    cat_l2?.length > 36
      ? 'grid-cols-5'
      : cat_l2?.length > 27
      ? 'grid-cols-4'
      : cat_l2?.length > 18
      ? 'grid-cols-3'
      : cat_l2?.length > 9
      ? 'grid-cols-2'
      : 'grid-cols-1'

  return (
    <>
      <header
        id="siteHeader"
        ref={siteHeaderRef}
        className={cn(
          'header-one w-full z-30 sticky -top-0.5',
          displayMobileSearch && 'active-mobile-search',
        )}
      >
        <div className="bg-brand-darkblue text-white flex justify-between items-center">
          {/* Left Section - Logo and Search Bar */}
          <Container className="flex md:justify-between sm:justify-between justify-start w-full h-full lg:justify-between 2xl:pr-0 xl:pr-0 lg:pr-0 max-w-[2100px]">
            {/* Logo */}
            <button
              aria-label="Menu"
              className="flex-col items-center justify-center outline-none menuBtn ltr:mr-5 rtl:ml-5 lg:flex shrink-0 focus:outline-none md:w-6 w-3 my-auto"
              onClick={handleMobileMenu}
            >
              <MenuIcon />
            </button>
            <div className="p-2 rounded w-2/5 sm:w-[20%] lg:w-[20%] 2xl:w-[15%] fsdLogo my-auto">
              <Link href={siteSettings.logo.href}>
                <Image
                  src="/assets/images/FSD-CAN-WHITE.svg"
                  alt="Logo"
                  width={500}
                  height={72}
                />
              </Link>
            </div>
            <div className="rounded fsdLogoMobile mr-2 my-auto">
              <Link href={siteSettings.logo.href}>
                <Image
                  src="/assets/images/FSD-CAN-WHITE-MOB.svg"
                  alt="Logo"
                  width={36}
                  height={36}
                  style={{ maxWidth: 'fit-content' }}
                />
              </Link>
            </div>

            <Search
              searchId="top-bar-search"
              className="relative flex items-center w-[80%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] md:w-[50%] sm:w-[40%] md:h-[50px] h-[30px] bg-white rounded-full shadow-lg md:ml-24 lg:ml-4 mr-2 my-auto searchNew"
              variant="fill"
            />

            {/* Search Bar */}

            <div className="userIcon my-auto">
              <AuthMenu
                isAuthorized={session?.token}
                href={ROUTES.ACCOUNT}
                btnPropsLogin={{
                  className: 'shrink-0 focus:outline-none text-white',
                  children: (
                    <AiOutlineUser className="w-5 h-5" aria-hidden="true" />
                  ),
                  onClick: handleLogin,
                  'aria-label': translate('text-sign-in'),
                  role: 'button',
                  'aria-haspopup': 'dialog',
                }}
                className="text-white"
              >
                <AiOutlineUser className="w-5 h-5" />
              </AuthMenu>
            </div>
            <div
              className="flex items-center block lg:hidden justify-center"
              onClick={isCheckoutPage ? handleCartPage : handleCartOpen}
            >
              <span className="min-w-[20px] min-h-[20px] p-0.5 ml-2 rounded-[9999px] flex items-center justify-center bg-white text-brand absolute top-1 text-[10px] font-medium cartCount">
                {totalItems}
              </span>
              <Image
                src="/assets/images/fsd-cart.svg"
                alt="Logo"
                width={40}
                height={48}
                className="h-12 w-10 ml-2"
              />
            </div>

            {/* Right Desktop Section */}
            <div className="container mx-auto flex flex-col items-end my-auto flex-wrap headerRightDesktop w-[55%]">
              <div className="flex space-x-2 text-base xl:text-base 2xl:text-base leading-2 2xl:leading-2">
                <Link href="/track-order" className="hover:underline">
                  {translate('track-order')}
                </Link>
                <span>|</span>
                <span className="flex">
                  <FaPhoneAlt className="mt-1 mr-3" />+ 855-705-0755
                </span>
                <span>|</span>
                <div className="flex space-x-1 xl:mx-3.5 mx-2.5 text-sm">
                  <LanguageSwitcher />
                </div>
              </div>

              <div className="flex space-x-2 text-base xl:text-base 2xl:text-base leading-5	xl:leading-5 2xl:leading-5">
                <AuthMenu
                  isAuthorized={session?.token}
                  href={ROUTES.ACCOUNT}
                  btnPropsLogin={{
                    className:
                      'shrink-0 focus:outline-none text-white border-solid	border-y-0 border-l-0 border-r border-grey-500 pr-4 border-white mr-0.5',
                    children: (
                      <Link href="#" className="flex hover:underline">
                        {' '}
                        <FiEdit className="mt-0.5 mr-3" />{' '}
                        {translate('text-create-an-account')}
                      </Link>
                    ),
                    onClick: handleSignUp,
                  }}
                  className="text-white"
                ></AuthMenu>
                <AuthMenu
                  isAuthorized={session?.token}
                  href={ROUTES.ACCOUNT}
                  btnPropsLogin={{
                    className: 'shrink-0 focus:outline-none text-white',
                    children: (
                      <Link href="#" className="flex hover:underline">
                        {' '}
                        <AiOutlineUser className="mt-0.5 mr-3" />{' '}
                        {translate('text-sign-in')}
                      </Link>
                    ),
                    onClick: handleLogin,
                  }}
                  className="text-white"
                >
                  Hi,{' '}
                  <span data-testid="header-text-username">
                    {user?.firstname}
                  </span>
                </AuthMenu>
              </div>
            </div>
            <div
              className="flex items-center bg-brand hidden lg:flex flex-col ml-2 w-[300px] lg:w-[150px] xl:w-[130px] cursor-pointer my-auto"
              onClick={isCheckoutPage ? handleCartPage : handleCartOpen}
            >
              <span className="min-w-[20px] min-h-[20px] p-0.5 rounded-[20px] flex items-center justify-center bg-white text-brand top-[3px] rtl:right-2.5 text-xs font-bold">
                {totalItems}
              </span>
              <Image
                src="/assets/images/fsd-cart.svg"
                alt="Logo"
                width={36}
                height={28}
                className="h-7 w-9 mt-[-6px]"
              />
              <span className="text-sm mb-1">{translate('your-cart')}</span>
            </div>
          </Container>
        </div>
      </header>

      <div className="bg-white p-2 homePageMenu overflow-x-auto border-b z-20">
        <div className="flex justify-around text-blue-900 w-full mx-auto min-w-[1750px] max-w-[1920px]">
          <button
            className="text-[17px] font-semibold flex text-brand"
            onClick={shopByDepartDropdown}
            ref={shopByDeptRef}
          >
            {translate('shop-by-department')}{' '}
            <IoIosArrowDown className="mt-1 ml-2" />{' '}
          </button>
          <button
            className="text-[17px]	font-semibold flex border-r	border-grey-500 text-brand"
            onClick={shopByBrandDropdown}
            ref={shopByBrandRef}
          >
            {translate('text-shop-by-brand', { ns: 'common' })}{' '}
            <IoIosArrowDown className="mt-1 ml-2 mr-8" />
          </button>
          <Link
            href="/foods.html"
            className="text-base	font-normal text-black"
            onClick={() => setIsOpenShopByDepart(false)}
          >
            {translate('foods', { ns: 'categories' })}
          </Link>
          <Link
            href="/beverages.html"
            className="text-base	font-normal text-black"
            onClick={() => setIsOpenShopByDepart(false)}
          >
            {translate('beverages', { ns: 'categories' })}
          </Link>
          <Link
            href="/international-foods.html"
            className="text-base	font-normal text-black"
            onClick={() => setIsOpenShopByDepart(false)}
          >
            {translate('international foods', { ns: 'categories' })}
          </Link>
          <Link
            href="/disposables.html"
            className="text-base	font-normal text-black"
            onClick={() => setIsOpenShopByDepart(false)}
          >
            {translate('disposables', { ns: 'categories' })}
          </Link>
          <Link
            href="/janitorial-supplies.html"
            className="text-base	font-normal text-black"
            onClick={() => setIsOpenShopByDepart(false)}
          >
            {translate('janitorial supplies', { ns: 'categories' })}
          </Link>
          <Link
            href="/kitchen-smallware.html"
            className="text-base	font-normal text-black"
            onClick={() => setIsOpenShopByDepart(false)}
          >
            {translate('kitchen smallware', { ns: 'categories' })}
          </Link>
          <Link
            href="/dinnerware.html"
            className="text-base	font-normal text-black"
            onClick={() => setIsOpenShopByDepart(false)}
          >
            {translate('dinnerware', { ns: 'categories' })}
          </Link>
          <Link
            href="/kitchen-equipment.html"
            className="text-base	font-normal text-black"
            onClick={() => setIsOpenShopByDepart(false)}
          >
            {translate('kitchen equipment', { ns: 'categories' })}
          </Link>
          <Link
            href="/health-and-personal-care.html"
            className="text-base	font-normal text-black"
            onClick={() => setIsOpenShopByDepart(false)}
          >
            {translate('health and personal care', { ns: 'categories' })}
          </Link>
        </div>
      </div>
      {isOpenShopByDepart && (
        <div
          className="!relative left-0 right-0 !-top-2 search-results mt-2 w-64 bg-white border rounded shadow-lg shopByDepart max-w-[1920px] mx-auto border-white h-[600px] overflow-auto z-[11] inset-x-0"
          ref={modalRef}
        >
          <div className="flex">
            <ul className=" text-brand font-medium text-xl w-1/3 p-4 divide-y divide-grey-500 px-0 border-r-2">
              <li
                className={`py-1 cursor-pointer h-16	flex items-center px-3 justify-between ${
                  selected === 'Foods' ? 'bg-gray-300' : 'bg-white'
                } hover:bg-gray-200`}
                onClick={() => {
                  setCatId('2821'), handleClick('Foods')
                }}
              >
                <span className="flex items-center">
                  <Image
                    src="/assets/images/category/foods_2_1.webp"
                    alt="Logo"
                    width={36}
                    height={36}
                    className="mr-2"
                  />
                  {translate('foods', { ns: 'categories' })}
                </span>{' '}
                <IoIosArrowForward />{' '}
              </li>
              <li
                className={`py-1 cursor-pointer h-16	flex items-center px-3 justify-between ${
                  selected === 'Beverages' ? 'bg-gray-300' : 'bg-white'
                } hover:bg-gray-200`}
                onClick={() => {
                  setCatId('3139'), handleClick('Beverages')
                }}
              >
                <span className="flex items-center">
                  <Image
                    src="/assets/images/category/fsd-beverages.webp"
                    alt="Logo"
                    width={36}
                    height={36}
                    className="mr-2"
                  />
                  {translate('beverages', { ns: 'categories' })}
                </span>
                <IoIosArrowForward />
              </li>
              <li
                className={`py-1 cursor-pointer h-16	flex items-center px-3 justify-between ${
                  selected === 'International Foods'
                    ? 'bg-gray-300'
                    : 'bg-white'
                } hover:bg-gray-200`}
                onClick={() => {
                  setCatId('2824'), handleClick('International Foods')
                }}
              >
                <span className="flex items-center">
                  <Image
                    src="/assets/images/category/fsd-int-foods.webp"
                    alt="Logo"
                    width={36}
                    height={36}
                    className="mr-2"
                  />
                  {translate('international foods', { ns: 'categories' })}
                </span>
                <IoIosArrowForward />
              </li>
              <li
                className={`py-1 cursor-pointer h-16	flex items-center px-3 justify-between ${
                  selected === 'Disposables' ? 'bg-gray-300' : 'bg-white'
                } hover:bg-gray-200`}
                onClick={() => {
                  setCatId('2836'), handleClick('Disposables')
                }}
              >
                <span className="flex items-center">
                  <Image
                    src="/assets/images/category/fsd-disposables.webp"
                    alt="Logo"
                    width={36}
                    height={36}
                    className="mr-2"
                  />
                  {translate('disposables', { ns: 'categories' })}
                </span>
                <IoIosArrowForward />
              </li>
              <li
                className={`py-1 cursor-pointer h-16	flex items-center px-3 justify-between ${
                  selected === 'Janitorial supplies'
                    ? 'bg-gray-300'
                    : 'bg-white'
                } hover:bg-gray-200`}
                onClick={() => {
                  setCatId('5770'), handleClick('Janitorial supplies')
                }}
              >
                <span className="flex items-center">
                  <Image
                    src="/assets/images/category/fsd-janitorial.webp"
                    alt="Logo"
                    width={36}
                    height={36}
                    className="mr-2"
                  />
                  {translate('janitorial supplies', { ns: 'categories' })}
                </span>
                <IoIosArrowForward />
              </li>
              <li
                className={`py-1 cursor-pointer h-16	flex items-center px-3 justify-between ${
                  selected === 'Kitchen smallware' ? 'bg-gray-300' : 'bg-white'
                } hover:bg-gray-200`}
                onClick={() => {
                  setCatId('2848'), handleClick('Kitchen smallware')
                }}
              >
                <span className="flex items-center">
                  <Image
                    src="/assets/images/category/fsd-kitchen.webp"
                    alt="Logo"
                    width={36}
                    height={36}
                    className="mr-2"
                  />
                  {translate('kitchen smallware', { ns: 'categories' })}
                </span>
                <IoIosArrowForward />
              </li>
              <li
                className={`py-1 cursor-pointer h-16	flex items-center px-3 justify-between ${
                  selected === 'Dinnerware' ? 'bg-gray-300' : 'bg-white'
                } hover:bg-gray-200`}
                onClick={() => {
                  setCatId('9001'), handleClick('Dinnerware')
                }}
              >
                <span className="flex items-center">
                  <Image
                    src="/assets/images/category/fsd-dinnerware.webp"
                    alt="Logo"
                    width={36}
                    height={36}
                    className="mr-2"
                  />
                  {translate('dinnerware', { ns: 'categories' })}
                </span>
                <IoIosArrowForward />
              </li>
              <li
                className={`py-1 cursor-pointer h-16	flex items-center px-3 justify-between ${
                  selected === 'Kitchen equipment' ? 'bg-gray-300' : 'bg-white'
                } hover:bg-gray-200`}
                onClick={() => {
                  setCatId('9268'), handleClick('Kitchen equipment')
                }}
              >
                <span className="flex items-center">
                  <Image
                    src="/assets/images/category/fsd-kitchen-equip.webp"
                    alt="Logo"
                    width={36}
                    height={36}
                    className="mr-2"
                  />
                  {translate('kitchen equipment', { ns: 'categories' })}
                </span>
                <IoIosArrowForward />
              </li>
              <li
                className={`py-1 cursor-pointer h-16	flex items-center px-3 justify-between ${
                  selected === 'Health and personal care'
                    ? 'bg-gray-300'
                    : 'bg-white'
                } hover:bg-gray-200`}
                onClick={() => {
                  setCatId('65790'), handleClick('Health and personal care')
                }}
              >
                <span className="flex items-center">
                  <Image
                    src="/assets/images/category/fsd-health.webp"
                    alt="Logo"
                    width={36}
                    height={36}
                    className="mr-2"
                  />
                  {translate('health and personal care', { ns: 'categories' })}
                </span>
                <IoIosArrowForward />
              </li>
            </ul>

            <div
              className={`w-[65%] p-4 pt-8 pl-8 grid ${columnClass} gap-4 h-2.5	`}
            >
              {cat_l2?.map((item) => {
                return (
                  <Link
                    href={`/${item.url_path}`}
                    className="py-1 h-16 text-brand-grey flex-grow hover:underline hover:-translate-y-1 duration-300"
                    key={item.id}
                    onClick={() => setIsOpenShopByDepart(false)}
                  >
                    {translate(`${item.name.toLowerCase()}`, {
                      ns: 'categories',
                    })}
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
      )}
      {isOpenShopByBrand && (
        <div
          ref={modalRef}
          className="w-[94%] !relative search-results bg-white border border-white shadow-lg rounded shopByDepart h-[58%] overflow-auto !top-0 z-10"
        >
          <BrandsGrid setIsOpenShopByBrand={setIsOpenShopByBrand} />
        </div>
      )}
    </>
  )
}

export default HeaderNew
