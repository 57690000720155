import { Item } from '@contexts/cart/cart.utils'
import { getSession } from 'next-auth/react'
import nodeSessionStorage from 'sessionstorage-for-nodejs'

type PriceDetails = {
  special_price: number
  special_from_date: string | null
  special_to_date: string | null
  price: number
}

export const formatPhoneNumber = (phoneNumberString: string) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return ['(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return phoneNumberString
}

export const createPricePayload = ({
  special_price,
  special_from_date,
  special_to_date,
  price,
}: Item): PriceDetails => {
  return { special_price, special_from_date, special_to_date, price }
}

export const getCurrentPrice = ({
  special_price,
  special_from_date,
  special_to_date,
  price,
}: PriceDetails): number => {
  const todayDate = new Date().toISOString().split('T')[0]
  const specialPrice = Number(special_price)
  const originalPrice = Number(price)

  if (!specialPrice || specialPrice >= originalPrice) return originalPrice
  if (!special_from_date && !special_to_date) return specialPrice
  if (
    special_from_date &&
    todayDate >= special_from_date &&
    (!special_to_date || todayDate <= special_to_date)
  )
    return specialPrice
  if (special_from_date && todayDate >= special_from_date) return specialPrice
  if (special_to_date && todayDate <= special_to_date) return specialPrice

  return originalPrice
}

export const getOldPrice = ({
  special_price,
  special_from_date,
  special_to_date,
  price,
}: PriceDetails): number | null => {
  const todayDate = new Date().toISOString().split('T')[0]
  const specialPrice = Number(special_price)
  const originalPrice = Number(price)

  if (!specialPrice || specialPrice >= originalPrice) return null
  if (!special_from_date && !special_to_date) return originalPrice
  if (
    special_from_date &&
    todayDate >= special_from_date &&
    (!special_to_date || todayDate <= special_to_date)
  )
    return originalPrice
  if (special_from_date && todayDate >= special_from_date) return originalPrice
  if (special_to_date && todayDate <= special_to_date) return originalPrice

  return null
}

export const getShipInDays = (
  ships_in_days: number,
  translate: (key: string) => string,
) => {
  let value: any, unit: string

  if (ships_in_days === 0) {
    ;[value, unit] = ['1', 'day']
  } else if (ships_in_days >= 21 && ships_in_days <= 30) {
    ;[value, unit] = ['3-4', 'week']
  } else if (ships_in_days > 30) {
    ;[value, unit] = ['6-7', 'week']
  } else {
    ;[value, unit] = [`${ships_in_days}`, 'day']
  }

  const shipsInDaysTranslationKeys = { day: 'text-day', week: 'text-week' }

  return {
    value,
    unit: translate(shipsInDaysTranslationKeys[unit]),
  }
}

export const cleanURLParams = (url) => {
  // Define unwanted characters
  const unwantedChars = /[<>#%{}/;#@%^*()!-=+,.?:|\\^~\[\]`]/g
  // Replace unwanted characters with an empty string
  return url.replace(unwantedChars, '')
}

export const getCollectionName = (locale?: string): string => {
  // If no locale provided, default to English Canada
  const normalizedLocale = locale?.toLowerCase() ?? 'en-ca'

  switch (normalizedLocale) {
    case 'en-ca':
      return String(process.env.NEXT_PUBLIC_TS_EN_CA_REPO ?? '')
    case 'fr-ca':
      return String(process.env.NEXT_PUBLIC_TS_FR_CA_REPO ?? '')
    default:
      throw new Error(`Unsupported locale: ${locale}`)
  }
}

export const updateSessionStore = async (sessionObj = null) => {
  const session = sessionObj ? sessionObj : await getSession()

  sessionStorage?.setItem('session', JSON.stringify(session))
  nodeSessionStorage?.setItem('session', JSON.stringify(session))
}

export const getSessionStore = () => {
  // Check if sessionStorage is defined before using it
  const sessionStore =
    typeof sessionStorage !== 'undefined' ? sessionStorage : nodeSessionStorage

  const sessionData =
    typeof sessionStorage !== 'undefined'
      ? JSON.parse(sessionStore.getItem('session'))
      : nodeSessionStorage.getItem('session')

  return sessionData
}

export const deleteSessionStore = () => {
  // Check if sessionStorage is defined before using it
  if (typeof sessionStorage !== 'undefined') {
    sessionStorage.removeItem('session')
  }
  nodeSessionStorage.removeItem('session')
}

export const getNameSlug = (brandName) => {
  return brandName
    .toLowerCase()
    .replaceAll('/', '-')
    .replaceAll('-', '')
    .replaceAll(' ', '-')
    .replaceAll('---', '-')
    .replaceAll('--', '-')
}
