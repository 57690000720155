import Button from '@components/ui/button'
import Link from '@components/ui/link'
import { ROUTES } from '@utils/routes'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'

export default function ShopByBrandsGrid({ setIsOpenShopByBrand }) {
  const { t: translate } = useTranslation('common')
  const router = useRouter()

  const featuredBrands = [
    {
      url: '/brand/kitch-247-disposables',
      name: "Kitch's",
      logo: '/assets/images/brand/Kitch.jpg',
    },
    {
      url: "/brand/hellmann's",
      name: "Hellmann's",
      logo: '/assets/images/brand/Hellmanns.png',
    },
    {
      url: '/brand/campbell',
      name: "Campbell's",
      logo: '/assets/images/brand/Campbellss.png',
    },
    {
      url: "/brand/amy's",
      name: "Amy's",
      logo: '/assets/images/brand/amys.svg',
    },
  ]

  const brands = [
    {
      url: '/brand/colgate',
      name: 'Colgate',
      logo: '/assets/images/brand/colgate.webp',
    },
    {
      url: '/brand/heinz',
      name: 'Heinz',
      logo: '/assets/images/brand/Heinz.jpg',
    },
    {
      url: '/brand/lipton',
      name: 'Lipton',
      logo: '/assets/images/brand/Lipton.png',
    },
    {
      url: '/brand/montague',
      name: "Amy's",
      logo: '/assets/images/brand/Montague.png',
    },
    {
      url: '/brand/tazo',
      name: 'Tazo',
      logo: '/assets/images/brand/Tazo.png',
    },
    {
      url: '/brand/zevia',
      name: 'Zevia',
      logo: '/assets/images/brand/zevia.png',
    },
    {
      url: '/brand/southbend',
      name: 'Southbend',
      logo: '/assets/images/brand/southbend_.png',
    },
    {
      url: '/brand/cleveland',
      name: 'Cleveland',
      logo: '/assets/images/brand/Cleveland.png',
    },
    {
      url: '/brand/ritz',
      name: 'Ritz',
      logo: '/assets/images/brand/ritz.png',
    },
    {
      url: '/brand/snuggle',
      name: 'Snuggle',
      logo: '/assets/images/brand/smuggle.png',
    },
    {
      url: '/brand/solo',
      name: 'Solo',
      logo: '/assets/images/brand/solo.png',
    },
  ]

  return (
    <div className="p-5 overflow-auto">
      <div className="flex justify-between mb-5">
        <h3 className="text-xl font-medium text-brand">
          {translate('text-featured-brands', { ns: 'common' })}
        </h3>

        <p className="text-xl font-medium text-brand">
          <Link
            href={ROUTES.BRANDS}
            onClick={() => {
              setIsOpenShopByBrand(false)
            }}
            className="w-full px-4 py-1 !h-4 text-[14px] rounded-md !font-medium font-poppins bg-brand text-white shadow-sm transition-shadow duration-200 hover:opacity-90"
          >
            {translate('text-view-all', { ns: 'common' })}
          </Link>
        </p>
      </div>
      <div className="w-full px-0 mx-auto">
        <div className="flex flex-wrap mx-0">
          <div className="flex-[0_0_30%] max-width-[30%]">
            <ul className="w-full flex flex-col items-center justify-center text-center">
              {featuredBrands?.map((manufacturer, index) => (
                <Link
                  href={manufacturer.url}
                  onClick={() => {
                    setIsOpenShopByBrand(false)
                  }}
                  key={`brand-${index}`}
                  className="w-full flex flex-col items-center justify-center text-center"
                >
                  <li
                    key={index}
                    className="w-full h-[106px] bg-white mb-4 shadow-dropDown flex pl-[70px] shopByBrand items-center"
                  >
                    <Image
                      src={manufacturer.logo}
                      alt={manufacturer.name}
                      width={118}
                      height={59}
                      className="h-[59px] w-[118px]"
                    />
                    <span className="text-grey-900 text-lg font-medium pl-[20px]">
                      {manufacturer.name}
                    </span>
                  </li>
                </Link>
              ))}
            </ul>
          </div>
          <div className="flex-[0_0_70%] max-width-[70%]">
            <ul className="flex flex-wrap w-full gap-2 pl-7">
              {brands?.map((brand, index) => (
                <Link
                  href={brand.url}
                  key={index}
                  onClick={() => {
                    setIsOpenShopByBrand(false)
                  }}
                >
                  <li className="w-[100px] h-[106px] bg-white mb-4 shadow-[0_6px_10px_5px_rgba(0,0,0,0.1)] flex justify-around items-center">
                    <Image
                      src={brand.logo}
                      alt={brand.name}
                      width={80}
                      height={44}
                      className="h-11 w-20"
                    />
                  </li>
                </Link>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
