export const ROUTES = {
  HOME: '/',
  CART: '/cart',
  PRODUCT: `/`,
  CHECKOUT: '/checkout',
  CONTACT: '/contact-us',
  TERMS: '/terms',
  PRIVACY: '/privacy',
  RETURN: '/return',
  FAQ: '/faq',
  FORGET_PASSWORD: '/forget-password',
  CHANGE_PASSWORD: '/my-account/change-password',
  ACCOUNT: '/my-account/account-settings',
  ORDERS: '/my-account/orders',
  ACCOUNT_SETTING: '/my-account/account-settings',
  ADDRESS: '/my-account/address',
  HELP_CENTER: '/my-account/help-center',
  LEGAL_NOTICE: '/my-account/legal-notice',
  NOTIFICATION: '/my-account/notification',
  SHOPS: '/shops',
  LOGIN: '/signin',
  SIGN_UP: '/signup',
  SEARCH: '/search',
  PRODUCTS: '/products',
  BUNDLE: '/bundles',
  CATEGORY: '/category',
  ORDER: '/complete-order',
  WISHLIST: '/my-account/wishlist',
  ACCOUNT_INFORMATION: '/my-account/account-information',
  REFERRAL_REWARDS: '/my-account/referral-rewards',
  STORED_PAYMENT_METHODS: '/my-account/stored-payment-methods',
  NOT_FOUND: '/404',
  BRANDS: '/brand',
  MANUFACTURERS: '/manufacturer',
}
